import cls from 'clsx';
import React from 'react';
import { FormSpinner } from '../FormSpinner/FormSpinner';
import styles from './Button.module.scss';

export interface ButtonStyleProps {
  color?: 'default' | 'primary' | 'secondary' | 'gray';
  variant?: 'contained' | 'outlined' | 'text' | 'icon';
  disabled?: boolean;
  fullWidth?: boolean;
  disableHover?: boolean;
  slimPadding?: boolean;
  isLoading?: boolean;
}
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonStyleProps;
export type AnchorButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonStyleProps;
export type LabelButtonProps = React.AnchorHTMLAttributes<HTMLLabelElement> &
  ButtonStyleProps;

function getClasses({
  color,
  variant,
  fullWidth,
  disabled,
  isLoading,
  disableHover,
  slimPadding,
}: ButtonStyleProps) {
  return cls(
    'reset-button',
    color && styles[color],
    { [styles.button]: variant },
    variant && styles[variant],
    fullWidth && styles.fullWidth,
    (disabled || isLoading) && styles.disabled,
    disableHover && styles.disableHover,
    slimPadding && styles.slimPadding
  );
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonBase(
    {
      children,
      type = 'button',
      color,
      variant,
      slimPadding,
      fullWidth = false,
      disabled,
      disableHover,
      className,
      isLoading,
      ...rest
    },
    ref
  ) {
    const classList = cls(
      getClasses({
        color,
        variant,
        fullWidth,
        disabled,
        disableHover,
        slimPadding,
        isLoading,
      }),
      className
    );
    return (
      <button
        ref={ref}
        type={type}
        {...rest}
        className={classList}
        disabled={disabled || isLoading}
      >
        {isLoading && (
          <div className={styles.spinnerContainer}>
            <FormSpinner />
          </div>
        )}
        <div className={cls(styles.content, { [styles.isLoading]: isLoading })}>
          {children}
        </div>
      </button>
    );
  }
);

export const AnchorButton = React.forwardRef<
  HTMLAnchorElement,
  AnchorButtonProps
>(function AnchorButtonBase(
  {
    children,
    color,
    variant,
    fullWidth = false,
    slimPadding,
    className,
    disableHover,
    ...rest
  },
  ref
) {
  const classList = cls(
    getClasses({ color, variant, fullWidth, disableHover, slimPadding }),
    className
  );
  return (
    <a ref={ref} {...rest} className={classList}>
      {children}
    </a>
  );
});

export const LabelButton = React.forwardRef<HTMLLabelElement, LabelButtonProps>(
  function LabelButtonBase(
    {
      children,
      color,
      variant,
      fullWidth = false,
      slimPadding,
      className,
      disableHover,
      ...rest
    },
    ref
  ) {
    const classList = cls(
      getClasses({ color, variant, fullWidth, disableHover, slimPadding }),
      className
    );
    return (
      <label ref={ref} {...rest} className={classList}>
        {children}
      </label>
    );
  }
);
