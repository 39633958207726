import React from 'react';
import { ImageContainer } from '@/components/atoms/ImageContainer/ImageContainer';
import {
  IconAmex,
  IconBancontact,
  IconCarteBancaire,
  IconDankort,
  IconIdeal,
  IconMastercard,
  IconPaypal,
  IconSepa,
  IconSofort,
  IconVisa,
} from '@/components/Icons/payments';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { PaymentMethod } from '@/types/PageData';
import { paymentMethods } from 'cms/data/pageData/checkoutPageData';
import styles from './PaymentLogo.module.scss';

interface Props {
  paymentMethod: PaymentMethod;
  iconSize?: number | string;
}

const paymentMethodIconMap: {
  [key: number]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  [paymentMethods.amex.value]: IconAmex,
  [paymentMethods.bancontact.value]: IconBancontact,
  [paymentMethods.carteBancaire.value]: IconCarteBancaire,
  [paymentMethods.dankort.value]: IconDankort,
  [paymentMethods.ideal.value]: IconIdeal,
  [paymentMethods.mastercard.value]: IconMastercard,
  [paymentMethods.payPal.value]: IconPaypal,
  [paymentMethods.sepaDirectDebit.value]: IconSepa,
  [paymentMethods.sofort.value]: IconSofort,
  [paymentMethods.visa.value]: IconVisa,
};

export const PaymentLogo = ({ paymentMethod, iconSize = 30 }: Props) => {
  const PaymentMethodIcon = paymentMethodIconMap[paymentMethod.value];

  if (PaymentMethodIcon) {
    return <PaymentMethodIcon width={iconSize} />;
  }

  return (
    <ImageContainer
      className={styles.paymentLogoContainer}
      ratioWidth={2}
      ratioHeight={1}
    >
      <CloudinaryImage
        src={paymentMethod.logo!}
        alt={paymentMethod.label}
        layout="fill"
        objectFit="contain"
        objectPosition="0 50%"
      />
    </ImageContainer>
  );
};
