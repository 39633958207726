import { useRadioGroup } from '@react-aria/radio';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { RadioGroupProps } from '@react-types/radio';
import cls from 'clsx';
import React from 'react';
import {
  Label,
  TypographyProps,
} from '@/components/atoms/Typography/Typography';
import { useId } from '@/utils/useId';
import styles from './RadioSwitch.module.scss';

interface Props extends Omit<RadioGroupProps, 'orientation'>, TypographyProps {
  children: React.ReactNode;
  className?: string;
  label?: string;
  labelChildren?: React.ReactNode; // For rendering components within a label instead of a string
  ariaLabel?: string;
}

export const RadioContext = React.createContext<RadioGroupState | null>(null);

export const RadioGroup = ({
  children,
  label,
  color,
  version,
  className,
  labelChildren,
  ariaLabel,
  ...rest
}: Props) => {
  const id = `radio-group-${useId()}`;
  const state = useRadioGroupState(rest);
  const { radioGroupProps, labelProps } = useRadioGroup(
    { ...rest, id, 'aria-label': ariaLabel || label },
    state
  );

  return (
    <div {...radioGroupProps} className={cls(styles.container, className)}>
      {!!label && (
        <Label
          {...labelProps}
          className={styles.groupLabel}
          color={color}
          version={version}
        >
          {label}
          {labelChildren}
        </Label>
      )}
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
};
