import * as React from 'react';
export function IconSofort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="60.98 290.14 564.69 102.39"
      aria-hidden={true}
      {...props}
    >
      <defs>
        <clipPath id="icon-sofort_svg__a" clipPathUnits="userSpaceOnUse">
          <path d="M0 512h512V0H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#icon-sofort_svg__a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
      >
        <path
          d="M162.81 293.248h-42.665c-6.728 0-13.265 0-19.116-2.97-5.856-2.974-11.025-8.923-15.007-20.811-.856-2.555-1.314-4.762-1.377-6.669-.06-1.924.275-3.555 1-4.97.898-1.724 2.347-3.355 4.164-4.724 1.814-1.373 3.992-2.487 6.33-3.203.089-.021 1.322-.377 2.814-.801a399.12 399.12 0 004.325-1.271c.644-.199 1.352-.453 1.996-.839.644-.385 1.22-.894 1.584-1.601.238-.475.365-.987.378-1.538a4.957 4.957 0 00-.297-1.784c-.542-1.614-1.373-2.656-2.95-3.292-1.571-.635-3.892-.839-7.41-.864H52.679l-6.944-19.07h46.995c4.423 0 12.507 0 20.28 3.3 7.772 3.301 15.22 9.902 18.376 23.1 1.187 4.961.822 9.007-1.122 12.18-1.953 3.174-5.48 5.474-10.614 6.949 0 0-1.148.335-2.504.72-1.343.39-2.897.835-3.694 1.064-1.314.372-2.326 1.135-2.924 2.101-.593.966-.771 2.144-.432 3.343.352 1.245 1.204 2.241 2.437 2.927 1.237.683 2.859 1.06 4.753 1.06H144.6c.195.275.373.576.564.86 4.986 7.054 11.028 12.77 17.646 16.803"
          fill="#f48120"
        />
        <path
          d="M162.833 293.331h-42.665c-6.728 0-13.261 0-19.116-2.97-5.856-2.974-11.02-8.919-15.003-20.81-.86-2.556-1.318-4.759-1.377-6.67-.059-1.919.275-3.55 1-4.966.894-1.728 2.343-3.359 4.16-4.728a20.566 20.566 0 016.335-3.199l2.813-.805c1.483-.428 3.224-.936 4.326-1.267.639-.203 1.351-.457 1.995-.838.64-.386 1.216-.899 1.58-1.606.238-.47.365-.983.378-1.538a4.923 4.923 0 00-.297-1.78c-.542-1.618-1.369-2.656-2.949-3.296-1.572-.635-3.889-.839-7.406-.864H52.701l-6.94-19.07h46.995c4.423 0 12.507 0 20.277 3.3 7.771 3.301 15.219 9.902 18.375 23.104 1.191 4.957.822 9.003-1.122 12.176-1.953 3.178-5.479 5.48-10.609 6.95 0 0-1.153.334-2.504.723-1.343.386-2.902.831-3.699 1.06-1.313.372-2.322 1.135-2.924 2.105-.588.966-.771 2.14-.432 3.34.356 1.244 1.204 2.24 2.437 2.926 1.237.683 2.859 1.06 4.753 1.06h27.319c.195.28.369.58.564.86 4.986 7.058 11.028 12.77 17.642 16.803M395.09 293.204a18.526 18.526 0 003.758-3.97c2.678-3.86 3.703-8.444 3.09-13.693h13.7l-20.65-56.76h20.633l20.663 56.76h26.51l6.436 17.663z"
          fill="#f48120"
        />
        <path
          d="M374.036 269.47c-2.212-5.953-6.177-9.63-13.723-9.63h-.034l-3.902.008 6.677 17.994h3.796c6.944 0 9.537-2.034 7.186-8.372m20.027 16.439c-3.398 4.885-10.304 7.283-21.124 7.296h-25.054l-27.09-74.407h23.01l9.855 26.187c.475-1.076 6.288-26.187 6.288-26.187h24.197s-4.402 18.45-7.64 25.904c-.83 1.919-1.698 4.067-2.86 5.859 6.835 2.538 16.74 7.995 20.838 19.04 2.525 6.792 2.377 12.279-.42 16.308M182.85 256.389c-6.376-17.456-11.007-21.85-17.973-21.85h-.012c-6.784.009-9.165 4.3-2.818 21.672 4.745 12.969 10.885 21.26 18.32 21.26h.004c7.063-.008 8.682-4.101 2.479-21.082m21.879 29.89c-3.716 5.318-10.084 8.02-18.935 8.03-5.787 0-11.414-1.331-16.68-3.818-7.541-3.563-14.371-9.52-19.951-17.413-3.627-5.131-6.728-11.071-9.16-17.72-4.576-12.535-4.525-22.716.161-29.42 2.652-3.795 8.169-8.324 19.392-8.333h.042c20.443 0 35.704 13.4 45.368 39.822 2.551 6.995 5.919 20.04-.237 28.853M286.418 293.248h-43.483c-12.155 0-21.205-6.152-25.476-17.663l-20.654-56.744h22.62l10.614 29.17 19.997-.016 5.686 15.625-19.93.013 3.207 9.045c.674 1.619 2.267 2.907 4.61 2.907 1.305 0 15.977 0 23.578-.005.195.28.369.581.564.865 4.986 7.054 12.049 12.77 18.667 16.803M306.195 256.389c-6.376-17.456-11.007-21.85-17.968-21.85h-.018c-6.787.009-9.155 4.3-2.808 21.672 4.736 12.969 10.884 21.26 18.307 21.26h.012c7.063-.008 8.682-4.101 2.475-21.082m21.883 29.89c-3.72 5.318-10.08 8.02-18.943 8.03-5.783 0-11.405-1.331-16.676-3.818-7.537-3.563-14.367-9.52-19.947-17.413-3.627-5.131-6.728-11.071-9.156-17.72-4.588-12.535-4.53-22.716.157-29.42 2.652-3.795 8.169-8.324 19.396-8.333h.038c20.439 0 35.704 13.4 45.36 39.822 2.559 6.995 5.923 20.04-.23 28.853"
          fill="#f48120"
        />
        <path
          d="M395.117 293.287a18.557 18.557 0 003.758-3.97c2.678-3.855 3.7-8.444 3.09-13.693h13.7l-20.65-56.76h20.633l20.66 56.76h26.513l6.432 17.663z"
          fill="#f48120"
        />
        <path
          d="M374.058 269.553c-2.208-5.95-6.177-9.626-13.723-9.626h-.03l-3.902.008 6.673 17.99h3.8c6.945 0 9.538-2.03 7.182-8.372m20.027 16.439c-3.393 4.885-10.3 7.283-21.12 7.296h-25.053l-27.094-74.407h23.014l9.85 26.187c.48-1.076 6.289-26.187 6.289-26.187h24.2s-4.406 18.45-7.64 25.908c-.83 1.915-1.698 4.063-2.86 5.859 6.835 2.538 16.737 7.99 20.838 19.036 2.52 6.792 2.373 12.279-.424 16.308M182.874 256.472c-6.376-17.456-11.007-21.845-17.973-21.845h-.012c-6.783.004-9.165 4.3-2.814 21.67 4.74 12.97 10.88 21.262 18.316 21.262h.004c7.067-.01 8.686-4.106 2.479-21.087m21.883 29.89c-3.72 5.318-10.084 8.02-18.938 8.034-5.792 0-11.414-1.335-16.681-3.818-7.541-3.567-14.371-9.52-19.951-17.417-3.622-5.127-6.728-11.071-9.156-17.714-4.58-12.541-4.53-22.722.157-29.425 2.656-3.796 8.173-8.321 19.392-8.334h.042c20.443 0 35.704 13.4 45.368 39.826 2.55 6.995 5.923 20.04-.233 28.849M286.444 293.331h-43.483c-12.159 0-21.209-6.152-25.48-17.663l-20.654-56.744h22.624l10.614 29.17 19.993-.012 5.686 15.621-19.93.013 3.211 9.045c.67 1.623 2.267 2.911 4.606 2.911 1.305 0 15.981-.004 23.582-.004.191.275.364.58.56.86 4.99 7.058 12.053 12.77 18.671 16.803M306.219 256.472c-6.376-17.456-11.003-21.845-17.964-21.845h-.017c-6.792.004-9.16 4.3-2.818 21.67 4.74 12.97 10.885 21.262 18.312 21.262h.013c7.062-.01 8.68-4.106 2.474-21.087m21.883 29.89c-3.72 5.318-10.08 8.02-18.94 8.034-5.786 0-11.408-1.335-16.675-3.818-7.541-3.567-14.371-9.52-19.951-17.417-3.627-5.127-6.724-11.071-9.156-17.714-4.584-12.541-4.53-22.722.16-29.425 2.649-3.796 8.166-8.321 19.397-8.334h.034c20.443 0 35.708 13.4 45.36 39.826 2.563 6.995 5.927 20.04-.23 28.849"
          fill="#f48120"
        />
      </g>
    </svg>
  );
}
