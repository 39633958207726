import * as React from 'react';
export function IconPaypal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="-23 0 302 302"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      aria-hidden={true}
      {...props}
    >
      <path
        d="M217.168 23.507C203.234 7.625 178.046.816 145.823.816h-93.52A13.393 13.393 0 0039.076 12.11L.136 259.077c-.774 4.87 2.997 9.28 7.933 9.28h57.736l14.5-91.971-.45 2.88c1.033-6.501 6.593-11.296 13.177-11.296h27.436c53.898 0 96.101-21.892 108.429-85.221.366-1.873.683-3.696.957-5.477-1.556-.824-1.556-.824 0 0 3.671-23.407-.025-39.34-12.686-53.765"
        fill="#27346A"
      />
      <path
        d="M102.397 68.84a11.737 11.737 0 015.053-1.14h73.318c8.682 0 16.78.565 24.18 1.756a101.6 101.6 0 016.177 1.182 89.928 89.928 0 018.59 2.347c3.638 1.215 7.026 2.63 10.14 4.287 3.67-23.416-.026-39.34-12.687-53.765C203.226 7.625 178.046.816 145.823.816H52.295C45.71.816 40.108 5.61 39.076 12.11L.136 259.068c-.774 4.878 2.997 9.282 7.925 9.282h57.744L95.888 77.58a11.717 11.717 0 016.509-8.74z"
        fill="#27346A"
      />
      <path
        d="M228.897 82.749c-12.328 63.32-54.53 85.221-108.429 85.221H93.024c-6.584 0-12.145 4.795-13.168 11.296L61.817 293.621c-.674 4.262 2.622 8.124 6.934 8.124h48.67a11.71 11.71 0 0011.563-9.88l.474-2.48 9.173-58.136.591-3.213a11.71 11.71 0 0111.562-9.88h7.284c47.147 0 84.064-19.154 94.852-74.55 4.503-23.15 2.173-42.478-9.739-56.054-3.613-4.112-8.1-7.508-13.327-10.28-.283 1.79-.59 3.604-.957 5.477z"
        fill="#2790C3"
      />
      <path
        d="M216.952 72.128a89.928 89.928 0 00-5.818-1.49 109.904 109.904 0 00-6.177-1.174c-7.408-1.199-15.5-1.765-24.19-1.765h-73.309a11.57 11.57 0 00-5.053 1.149 11.683 11.683 0 00-6.51 8.74l-15.582 98.798-.45 2.88c1.025-6.501 6.585-11.296 13.17-11.296h27.444c53.898 0 96.1-21.892 108.428-85.221.367-1.873.675-3.688.958-5.477-3.122-1.648-6.501-3.072-10.14-4.279a83.26 83.26 0 00-2.77-.865"
        fill="#1F264F"
      />
    </svg>
  );
}
