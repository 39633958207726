import { ImSpinner10 } from '@react-icons/all-files/im/ImSpinner10';
import { motion } from 'framer-motion';
import React from 'react';
import styles from './FormSpinner.module.scss';

interface Props {
  size?: string | number;
}

export const FormSpinner = ({ size }: Props) => (
  <motion.span
    layout
    className={styles.loaderWrapper}
    animate={{ rotate: [0, 100, 270, 360] }}
    transition={{
      duration: 1,
      ease: 'easeInOut',
      repeat: Infinity,
    }}
  >
    <ImSpinner10 size={size} />
  </motion.span>
);
