import * as React from 'react';
export function IconIdeal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="icon-ideal_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 306.1 269.8"
      xmlSpace="preserve"
      aria-hidden={true}
      {...props}
    >
      <style>{'.icon-ideal_svg__st0{fill:#fff}'}</style>
      <path
        className="icon-ideal_svg__st0"
        d="M0 20v229.8c0 11 9 20 20 20h137.3c103.8 0 148.8-58.1 148.8-135.2C306.1 57.9 261.1 0 157.3 0H20C9 0 0 9 0 20z"
      />
      <path
        d="M91.9 56.4v169.8h73.9c67.1 0 96.2-37.9 96.2-91.5 0-51.3-29.1-91.1-96.2-91.1h-61.1c-7.1 0-12.8 5.8-12.8 12.8z"
        fill="#c06"
      />
      <path d="M157.3 251.5H37.9c-10.6 0-19.2-8.6-19.2-19.2V37.6c0-10.6 8.6-19.2 19.2-19.2h119.4c113.3 0 130.2 72.9 130.2 116.3 0 75.3-46.3 116.8-130.2 116.8zM37.9 24.8c-7.1 0-12.8 5.7-12.8 12.8v194.7c0 7.1 5.7 12.8 12.8 12.8h119.4c79.8 0 123.8-39.2 123.8-110.4 0-95.6-77.6-109.9-123.8-109.9H37.9z" />
      <path
        className="icon-ideal_svg__st0"
        d="M117.9 111.8c2.6 0 5 .4 7.3 1.2 2.3.8 4.2 2.1 5.9 3.7 1.6 1.7 2.9 3.8 3.9 6.2.9 2.5 1.4 5.4 1.4 8.8 0 3-.4 5.7-1.1 8.2-.8 2.5-1.9 4.7-3.4 6.5-1.5 1.8-3.4 3.2-5.7 4.3-2.3 1-5 1.6-8.1 1.6h-17.5v-40.6h17.3zm-.6 33.1c1.3 0 2.5-.2 3.8-.6 1.2-.4 2.3-1.1 3.2-2.1.9-1 1.7-2.2 2.3-3.8.6-1.6.9-3.4.9-5.7 0-2-.2-3.9-.6-5.5-.4-1.6-1.1-3.1-2-4.2s-2.1-2.1-3.6-2.7c-1.5-.6-3.3-.9-5.5-.9h-6.4V145h7.9zM172.5 111.8v7.5h-21.4v8.7h19.7v6.9h-19.7v9.9H173v7.5h-30.8v-40.6h30.3zM203.1 111.8l15.2 40.6H209l-3.1-9h-15.2l-3.2 9h-9l15.3-40.6h9.3zm.5 24.9l-5.1-14.9h-.1l-5.3 14.9h10.5zM232.8 111.8v33.1h19.8v7.5h-28.7v-40.6h8.9z"
      />
      <g>
        <circle cx={58.5} cy={132.1} r={18.7} />
      </g>
      <path d="M72.6 226.2c-15.7 0-28.3-12.7-28.3-28.3v-22.1c0-7.8 6.3-14.2 14.2-14.2 7.8 0 14.2 6.3 14.2 14.2v50.4z" />
    </svg>
  );
}
