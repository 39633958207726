import { FaLongArrowAltRight } from '@react-icons/all-files/fa/FaLongArrowAltRight';
import cls from 'clsx';
import React from 'react';
import styles from './A.module.scss';

interface AStyleProps {
  color?: 'primary' | 'white' | 'default';
  version?: 'level3' | 'level4' | 'level5' | 'body1' | 'body2';
  withArrow?: boolean;
}

export type AProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  AStyleProps;
export type AButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  AStyleProps;

function getClasses({ color, version = 'level5', withArrow }: AStyleProps) {
  return cls(
    styles.link,
    color && styles[color],
    styles[version],
    withArrow && styles.withArrow
  );
}

const icon = <FaLongArrowAltRight preserveAspectRatio="xMidYMid slice" />;

export const A = React.forwardRef<HTMLAnchorElement, AProps>(function ABase(
  { children, color, className, withArrow, version, ...rest },
  ref
) {
  const classList = cls(getClasses({ color, withArrow, version }), className);
  return (
    <a {...rest} className={classList} ref={ref}>
      {children}
      {withArrow && icon}
    </a>
  );
});

export const AButton = React.forwardRef<HTMLButtonElement, AButtonProps>(
  function AButtonBase(
    { children, color, className, withArrow, version, ...rest },
    ref
  ) {
    const classList = cls(
      'reset-button',
      getClasses({ color, withArrow, version }),
      className
    );

    return (
      <button {...rest} className={classList} ref={ref}>
        {children}
        {withArrow && icon}
      </button>
    );
  }
);
