import { ExcludeRouterProps } from 'next/dist/client/with-router';
import dynamic from 'next/dynamic';
import type { RouterProgressBarProps } from './RouterProgressBarComponent';

export const RouterProgressBar = dynamic<
  ExcludeRouterProps<RouterProgressBarProps>
>(
  () =>
    import('./RouterProgressBarComponent').then(
      mod => mod.RouterProgressBarComponent
    ),
  {
    ssr: false,
  }
);
