import React from 'react';
import { CMSData } from '@/types/CMSData';
import { PageData } from '@/types/PageData';

const PageDataContext = React.createContext<CMSData<keyof PageData> | null>(
  null
);

interface PageDataContextProps {
  children: React.ReactNode;
  data: CMSData<keyof PageData> | null;
}

const PageDataContextProvider = ({ children, data }: PageDataContextProps) => (
  <PageDataContext.Provider value={data}>{children}</PageDataContext.Provider>
);

function useData<T extends keyof PageData>(): CMSData<T> {
  const context = React.useContext(PageDataContext);

  if (context === undefined) {
    throw new Error('useData must be used within a PageDataContextProvider');
  }
  if (context === null) {
    throw new Error('PageDataContext is not initialized correctly');
  }

  return context as CMSData<T>;
}

export { PageDataContextProvider, useData };
