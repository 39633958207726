import { MdClose } from '@react-icons/all-files/md/MdClose';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffectOnce, useMedia } from 'react-use';
import useLocalStorageState from 'use-local-storage-state';
import { P } from '@/components/atoms/Typography/Typography';
import { NavLink } from '@/components/local/NavLink/NavLink';
import { useData } from '@/lib/pageData/pageDataContext';
import breakpoints from '@/theme/breakpoints.module.scss';
import { NotificationsContent } from '@/types/Notifications';
import { getOffsetDate, isAfterDate } from '@/utils/date';
import { mapRoutesToPageNames } from '@/utils/mapRoutesToPageNames';
import styles from './NotificationBanner.module.scss';

type NotificationBannerContentProps = {
  notifications: NotificationsContent;
  onClose: (event: React.MouseEvent) => void;
};

const NotificationBannerContent = ({
  onClose,
  notifications,
}: NotificationBannerContentProps) => {
  const { t } = useTranslation();
  const iSmallScreen = useMedia(`(max-width: ${breakpoints.mediaSM})`, false);

  return (
    <>
      <P className={styles.text} version="level5" color="white">
        {t(`common:notifications.${notifications.textKey}`)}
        {notifications.link && !iSmallScreen && (
          <NavLink id={styles.link} link={notifications.link}>
            {t(`common:notifications.${notifications.link.key}`)}
          </NavLink>
        )}
      </P>
      <div className={styles.closeIconContainer}>
        <MdClose className={styles.closeIcon} onClick={onClose} />
      </div>
    </>
  );
};

export const NotificationBanner = () => {
  const router = useRouter();
  const { notifications } = useData<'default'>();
  const [notificationBannerState, setNotificationBannerState] =
    useLocalStorageState<{ isClosed: boolean; expires?: string }>(
      'notificationBannerState'
    );

  const shouldBeDisplayedOnPage = notifications?.pages?.includes(
    mapRoutesToPageNames(router)
  );

  useEffectOnce(() => {
    const isExpired = isAfterDate(new Date(), notificationBannerState?.expires);

    if (isExpired) {
      setNotificationBannerState({ isClosed: false });
    }
  });

  if (
    !notifications ||
    !shouldBeDisplayedOnPage ||
    notificationBannerState?.isClosed
  ) {
    return null;
  }

  const onClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setNotificationBannerState({
      isClosed: true,
      expires: getOffsetDate(new Date(), 12, 'hours').toString(),
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className={styles.banner}
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto' }}
        transition={{ opacity: 0, height: 0, delay: 1 }}
      >
        {!!('textKey' in notifications) &&
          (notifications.link ? (
            <NavLink className={styles.content} link={notifications.link}>
              <NotificationBannerContent
                onClose={onClose}
                notifications={notifications}
              />
            </NavLink>
          ) : (
            <div className={styles.content}>
              <NotificationBannerContent
                onClose={onClose}
                notifications={notifications}
              />
            </div>
          ))}
      </motion.div>
    </AnimatePresence>
  );
};
