import cls from 'clsx';
import React from 'react';
import styles from './ImageContainer.module.scss';

export interface ImageContainerProps {
  children: React.ReactNode;
  ratioWidth: number;
  ratioHeight: number;
  className?: string;
}

export const ImageContainer = ({
  children,
  ratioHeight,
  ratioWidth,
  className,
}: ImageContainerProps) => (
  <div
    className={cls(styles.container, className)}
    style={{ paddingTop: `calc(${ratioHeight} / ${ratioWidth} * 100%)` }}
  >
    {children}
  </div>
);
