import * as React from 'react';
export function IconSepa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="140.31 65.31 319.38 319.38"
      aria-hidden={true}
      {...props}
    >
      <symbol id="icon-sepa_svg__a" viewBox="-415.1 -415.1 830.1 830.1">
        <path
          d="M-276.7-415.1h553.4c92.2 0 138.4 46.1 138.4 138.4v553.4c0 92.2-46.1 138.4-138.4 138.4h-553.4c-92.2 0-138.4-46.1-138.4-138.4v-553.4c0-92.2 46.2-138.4 138.4-138.4z"
          fill="#26367e"
        />
        <path
          d="M-342.2-42.8c0-3.7 1.9-5.6 1.9-9.3 3.7-22.3 13-31.6 33.5-35.3 26-5.6 52.1-5.6 78.1-1.9s37.2 18.6 37.2 42.8v11.2h-44.6v-1.9c-1.9-16.7-5.6-18.6-22.3-18.6H-277c-11.2 1.9-16.7 5.6-16.7 18.6 0 11.2 3.7 16.7 16.7 16.7 11.2 0 22.3 0 33.5 1.9L-210-13c14.9 3.7 22.3 14.9 24.2 29.8 0 13 0 27.9-1.9 40.9-1.9 14.9-11.2 24.2-24.2 27.9-11.2 3.7-20.5 5.6-31.6 7.4H-277c-11.2-1.9-22.3-1.9-33.5-3.7-16.7-3.7-27.9-14.9-29.8-31.6 0-1.9-1.9-5.6-1.9-7.4V33.5h44.6c1.9 16.7 5.6 22.3 16.7 22.3h33.5c11.2 0 14.9-7.4 14.9-18.6s-5.6-16.7-14.9-16.7c-16.7-1.9-35.3-3.7-52.1-5.6-24.2-1.9-35.3-13-39.1-37.2 0-1.9-1.9-3.7-1.9-5.6-1.7-5.6-1.7-9.3-1.7-14.9zM18.6 93V-83.7c0-7.4 1.9-9.3 9.3-9.3 31.6 0 63.2 0 94.9 1.9 33.5 1.9 50.2 18.6 52.1 52.1 1.9 14.9 0 29.8-1.9 44.6-3.7 22.3-20.5 37.2-44.6 37.2H65.1v48.4C50.2 93 33.5 93 18.6 93zM65.1 1.9h42.8c11.2 0 14.9-5.6 14.9-16.7v-18.6c0-13-5.6-18.6-18.6-20.5H70.7c-1.9 0-5.6 3.7-5.6 3.7V1.9zM154.4 93c11.2-39.1 24.2-80 35.3-119 7.4-22.3 13-44.6 20.5-67h70.7c1.9 0 5.6 3.7 5.6 5.6L336.7 80c1.9 3.7 3.7 9.3 5.6 13H292c-3.7-9.3-5.6-18.6-9.3-27.9-1.9-1.9-3.7-5.6-5.6-5.6h-59.5c-1.9 0-5.6 1.9-5.6 3.7-3.7 9.3-5.6 20.5-7.5 29.8h-50.1zm94.9-146.9h-1.9c-7.4 26-14.9 53.9-22.3 80h48.4c-7.5-28-16.8-54-24.2-80z"
          fill="#fff"
        />
        <path
          d="M-42.8 63.2c-29.8 0-52.1-13-61.4-33.5h67l7.4-20.5h-81.9V-5.6L-28-1.9l9.3-24.1H-108c9.3-26 33.5-39.1 65.1-39.1 13.4-.1 26.6 2.5 39.1 7.4l7.4-26c-7.4-3.7-24.2-7.4-48.4-7.4-50.2 0-89.3 24.2-102.3 67h-22.3l-9.3 20.5h27.9V13h-16.7l-11.2 20.5h31.6C-135.8 70.7-100.4 93-50.2 93-26 93-7.4 89.3 0 85.5l-5.6-26c-7.4 1.9-22.3 3.8-37.2 3.7z"
          fill="#fcbd0c"
        />
      </symbol>
      <use
        xlinkHref="#icon-sepa_svg__a"
        width={830.1}
        height={830.1}
        x={-415.1}
        y={-415.1}
        transform="translate(300 225) scale(.3847)"
        overflow="visible"
      />
    </svg>
  );
}
