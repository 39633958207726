import * as React from 'react';
export function IconMastercard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="17.91 18 116.49 72"
      aria-hidden={true}
      {...props}
    >
      <path fill="none" d="M0 0h152.407v108H0z" />
      <path fill="#ff5f00" d="M60.412 25.697h31.5v56.606h-31.5z" />
      <path
        d="M62.412 54a35.938 35.938 0 0113.75-28.303 36 36 0 100 56.606A35.938 35.938 0 0162.412 54z"
        fill="#eb001b"
      />
      <path
        d="M134.407 54a35.999 35.999 0 01-58.245 28.303 36.005 36.005 0 000-56.606A35.999 35.999 0 01134.407 54zM130.972 76.308v-1.16h.468v-.235h-1.19v.236h.467v1.159zm2.31 0V74.91h-.364l-.42.962-.42-.962h-.364v1.398h.257v-1.054l.394.908h.267l.394-.91v1.056z"
        fill="#f79e1b"
      />
    </svg>
  );
}
