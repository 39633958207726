import * as React from 'react';
export function IconVisa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920.01 620.08"
      aria-hidden={true}
      {...props}
    >
      <path
        d="M729 10.96L477.63 610.7h-164l-123.7-478.62c-7.51-29.48-14.04-40.28-36.88-52.7C115.76 59.15 54.18 40.17 0 28.39l3.68-17.43h263.99c33.65 0 63.9 22.4 71.54 61.15l65.33 347.04L566 10.95h163zm642.58 403.93c.66-158.29-218.88-167.01-217.37-237.72.47-21.52 20.96-44.4 65.81-50.24 22.23-2.91 83.48-5.13 152.95 26.84l27.25-127.18C1362.89 13.04 1314.86 0 1255.1 0c-153.35 0-261.27 81.52-262.18 198.25-.99 86.34 77.03 134.52 135.81 163.21 60.47 29.38 80.76 48.26 80.53 74.54-.43 40.23-48.23 57.99-92.9 58.69-77.98 1.2-123.23-21.1-159.3-37.87l-28.12 131.39c36.25 16.63 103.16 31.14 172.53 31.87 162.99 0 269.61-80.51 270.11-205.19m404.94 195.81h143.49L1794.76 10.96h-132.44c-29.78 0-54.9 17.34-66.02 44L1363.49 610.7h162.91l32.34-89.58h199.05l18.73 89.58zm-173.11-212.5l81.66-225.18 47 225.18h-128.66zM950.67 10.96L822.38 610.7H667.24L795.58 10.96h155.09z"
        fill="#1434cb"
      />
    </svg>
  );
}
