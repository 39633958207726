import * as React from 'react';
export function IconDankort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="53.46 52.41 641.64 386.52"
      aria-hidden={true}
      {...props}
    >
      <defs>
        <clipPath id="icon-dankort_svg__a" clipPathUnits="userSpaceOnUse">
          <path d="M40.068 25.193h481.229v318.119H40.068z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#icon-dankort_svg__a)"
        transform="matrix(1.33333 0 0 -1.33333 0 491.339)"
      >
        <path
          d="M185.053 326.313c-78.338 0-142.073-63.725-142.073-142.053 0-78.336 63.735-142.067 142.073-142.067h191.332c78.328 0 142.054 63.73 142.054 142.067 0 78.328-63.726 142.053-142.054 142.053z"
          fill="#fff"
        />
        <path
          d="M185.053 326.313c-78.338 0-142.073-63.725-142.073-142.053 0-78.336 63.735-142.067 142.073-142.067h191.332c78.328 0 142.054 63.73 142.054 142.067 0 78.328-63.726 142.053-142.054 142.053zM376.385 39.308H185.053c-79.93 0-144.958 65.025-144.958 144.952 0 79.919 65.028 144.939 144.958 144.939h191.332c79.92 0 144.94-65.02 144.94-144.94 0-79.927-65.02-144.952-144.94-144.952"
          fill="#fff"
        />
        <path
          d="M409.832 192.934l63.624-75.666c13.177 19.043 20.916 42.126 20.916 66.99 0 26.244-8.622 50.514-23.171 70.13zM229.541 260.776c47.513 0 83.313-12.09 86.998-52.121l50.004 52.127h99.569c-21.66 25.352-53.848 41.457-89.727 41.457H185.053c-35.892 0-68.086-16.105-89.747-41.463zM151.257 210.31l-16.967-46.65h68.455c20.237 0 26.36 8.378 30.89 24.507 4.481 15.953-6.777 22.143-20.607 22.143zM366.959 110.898l-52.885 66.835c-9.783-45.569-41.792-66.827-101.797-66.827H92.69c21.634-27.185 54.995-44.647 92.363-44.647h191.333c37.354 0 70.708 17.455 92.343 44.64z"
          fill="#e41d27"
        />
      </g>
    </svg>
  );
}
