import cls from 'clsx';
import Link from 'next/link';
import React from 'react';
import { IconBrandLegacy } from '@/components/Icons/IconBrandLegacy';
import { NotificationBanner } from '../NotificationBanner/NotificationBanner';
import styles from './Navbar.module.scss';

export interface NavbarProps {
  className?: string;
  rightSlot?: React.ReactNode;
  centerSlot?: React.ReactNode;
  showNotificationBanner?: boolean;
}

export const Navbar = ({
  className,
  rightSlot,
  centerSlot,
  showNotificationBanner = true,
}: NavbarProps) => (
  <div className={cls(styles.container, className)} data-test-id="navbar">
    {showNotificationBanner && <NotificationBanner />}
    <nav className={styles.navbar}>
      <Link href="/" prefetch={false} passHref>
        <a className={styles.link}>
          <IconBrandLegacy />
        </a>
      </Link>
      {centerSlot && <div className={styles.centerSlot}>{centerSlot}</div>}
      {rightSlot && <div className={styles.rightSlot}>{rightSlot}</div>}
    </nav>
  </div>
);
