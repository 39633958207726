import { gtmDataLayerPush } from './gtmDataLayerPush';

export const pushInteractionEvent = ({
  name,
  details,
  ...rest
}: {
  name: string;
  details?: string;
  coupon?: string;
}) =>
  gtmDataLayerPush({
    event: 'interaction_event',
    event_name: name,
    event_details: details,
    ...rest,
  });

export const pushEcommerceNullEvent = () =>
  gtmDataLayerPush({
    event: 'ecommerceNull',
    ecommerce: null,
  });
