import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { useRadio } from '@react-aria/radio';
import { MdRadioButtonChecked } from '@react-icons/all-files/md/MdRadioButtonChecked';
import { MdRadioButtonUnchecked } from '@react-icons/all-files/md/MdRadioButtonUnchecked';
import { RadioProps } from '@react-types/radio';
import cls from 'clsx';
import React, { MutableRefObject, useContext } from 'react';
import { useEnsuredForwardedRef } from 'react-use';
import { LabelButton } from '@/components/atoms/Button/Button';
import { RadioContext } from '@/components/atoms/RadioSwitch/RadioGroup';
import styles from './RadioSwitch.module.scss';

export interface RadioInputLabelProps {
  isSelected: boolean;
  isFocusVisible: boolean;
  isRadioIconHidden: boolean;
  className?: string;
  children: React.ReactNode;
  slimPadding?: boolean;
  thickActiveBorder?: boolean;
}
interface Props extends Omit<RadioProps, 'value'> {
  'aria-label': string;
  value: unknown;
  children: React.ReactNode | React.ReactNodeArray | string;
  className?: string;
  slimPadding?: boolean;
  thickActiveBorder?: boolean;
  isRadioIconHidden?: boolean;
  labelComponent?: (params: RadioInputLabelProps) => JSX.Element;
}

const RadioIcon = ({ isSelected }: Pick<RadioInputLabelProps, 'isSelected'>) =>
  isSelected ? (
    <MdRadioButtonChecked className={styles.icon} />
  ) : (
    <MdRadioButtonUnchecked className={styles.icon} />
  );

const DefaultLabelComponent = ({
  isSelected,
  isFocusVisible,
  className,
  children,
  isRadioIconHidden = false,
  slimPadding = false,
  thickActiveBorder = false,
}: RadioInputLabelProps) => (
  <LabelButton
    variant="outlined"
    color={isSelected ? 'primary' : 'gray'}
    disableHover={isSelected}
    data-test-id="radio-button"
    className={cls(
      styles.label,
      { [styles.selected]: isSelected },
      { [styles.focus]: isFocusVisible },
      { [styles.thickActiveBorder]: isSelected && thickActiveBorder },
      className
    )}
    slimPadding={slimPadding}
  >
    {!isRadioIconHidden && <RadioIcon isSelected={isSelected} />}
    {children}
  </LabelButton>
);

export const RadioInput = React.forwardRef<HTMLInputElement, Props>(
  function RadioInputComponent(props, ref) {
    const {
      children,
      className,
      labelComponent,
      value,
      isRadioIconHidden,
      slimPadding,
      thickActiveBorder,
      ...rest
    } = props;
    const state = useContext(RadioContext);
    const radioInputRef = useEnsuredForwardedRef(
      ref as MutableRefObject<HTMLInputElement>
    );
    const { inputProps } = useRadio(
      { ...rest, value: value as string },
      state!,
      radioInputRef as React.MutableRefObject<HTMLInputElement>
    );
    const { isFocusVisible, focusProps } = useFocusRing();

    const WrappingLabelComponent = labelComponent
      ? labelComponent
      : DefaultLabelComponent;
    const isSelected = state?.selectedValue === value;

    return (
      <WrappingLabelComponent
        isSelected={isSelected}
        isFocusVisible={isFocusVisible}
        className={className}
        isRadioIconHidden={!!isRadioIconHidden}
        slimPadding={slimPadding}
        thickActiveBorder={thickActiveBorder}
      >
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={radioInputRef} />
        </VisuallyHidden>
        {children}
      </WrappingLabelComponent>
    );
  }
);
